import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './styles/theme';

import EarlyAccessLandingPage from './components/earlyAccess/EarlyAccessLandingPage';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <EarlyAccessLandingPage />
    </ThemeProvider>
  );
};

export default App;