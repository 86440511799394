import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authReducer';
//import employeeReducer from './employeeReducer';
//import attendanceReducer from './attendanceReducer';
//import leaveReducer from './leaveReducer';
//import payrollReducer from './payrollReducer';
//import performanceReducer from './performanceReducer';
//import recruitmentReducer from './recruitmentReducer';
//import trainingReducer from './trainingReducer';
//import reportingReducer from './reportingReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  //employee: employeeReducer,
  //attendance: attendanceReducer,
  //leave: leaveReducer,
  //payroll: payrollReducer,
  //performance: performanceReducer,
  //recruitment: recruitmentReducer,
  //training: trainingReducer,
  //reporting: reportingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
