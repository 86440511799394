// src/services/auth.ts
import axios from 'axios';
import { getToken, setToken, removeToken } from '../utils/localStorage';

const API_URL = process.env.REACT_APP_API_URL || 'http://api-gateway:3000';

const authApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

authApi.interceptors.request.use((config) => {
  const token = getToken();
  if (token && !config.url?.includes('/api/auth/register') && !config.url?.includes('/api/auth/send-registration-otp') && !config.url?.includes('/api/auth/verify-registration-otp')) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

authApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error('Server responded with error:', error.response.data);
      throw new Error(error.response.data.message || 'An error occurred');
    } else if (error.request) {
      console.error('No response received:', error.request);
      throw new Error('No response from server. Please try again.');
    } else {
      console.error('Error setting up request:', error.message);
      throw new Error('An error occurred. Please try again.');
    }
  }
);

export const authService = {
  login: async (credentials: { email: string; password: string }) => {
    const response = await authApi.post('/api/auth/login', credentials);
    if (response.data.token) {
      setToken(response.data.token);
    }
    return response;
  },

  socialLogin: async (provider: string) => {
    const response = await authApi.get(`/api/auth/social-login/${provider}`);
    if (response.data.token) {
      setToken(response.data.token);
    }
    return response;
  },

  logout: () => {
    removeToken();
  },

  registerTenant: async (tenantData: any) => {
    return await authApi.post('/api/tenants/register', tenantData);
  },

  sendOTP: async (email: string) => {
    try {
      const response = await authApi.post('/api/auth/send-registration-otp', { email });
      return response.data;
    } catch (error) {
      console.error('Error sending OTP:', error);
      throw error;
    }
  },

  verifyOTP: async (data: { email: string; otp: string }) => {
    try {
      const response = await authApi.post('/api/auth/verify-registration-otp', data);
      return response.data;
    } catch (error) {
      console.error('Error verifying OTP:', error);
      throw error;
    }
  },

  forgotPassword: async (email: string) => {
    return await authApi.post('/api/auth/forgot-password', { email });
  },

  resetPassword: async (data: { token: string; newPassword: string }) => {
    return await authApi.post('/api/auth/reset-password', data);
  },

  verifyEmail: async (token: string) => {
    return await authApi.get(`/api/auth/verify-email/${token}`);
  },

  refreshToken: async () => {
    const response = await authApi.post('/api/auth/refresh-token');
    if (response.data.token) {
      setToken(response.data.token);
    }
    return response;
  },

  getTenantOptions: async () => {
    try {
      console.log('Fetching tenant options from:', `${API_URL}/api/tenants/options`);
      const response = await authApi.get('/api/tenants/options');
      console.log('Tenant options response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching tenant options:', error);
      if (axios.isAxiosError(error)) {
        console.error('Axios error details:', error.response?.data);
      }
      throw error;
    }
  },
};

export default authService;