// src/store/actions/authActions.ts

import { createAsyncThunk } from '@reduxjs/toolkit';
import { authService } from '../../services/auth';

export const loginUser = createAsyncThunk(
  'auth/login',
  async (credentials: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await authService.login(credentials);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginWithSocial = createAsyncThunk(
  'auth/socialLogin',
  async (provider: string, { rejectWithValue }) => {
    try {
      const response = await authService.socialLogin(provider);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerTenant = createAsyncThunk(
  'auth/registerTenant',
  async (tenantData: any, { rejectWithValue }) => {
    try {
      const response = await authService.registerTenant(tenantData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || { message: 'An error occurred during registration' });
    }
  }
);

export const sendRegistrationOTP = createAsyncThunk(
  'auth/sendRegistrationOTP',
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await authService.sendOTP(email);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || { message: 'Failed to send OTP' });
    }
  }
);

export const verifyRegistrationOTP = createAsyncThunk(
  'auth/verifyRegistrationOTP',
  async (data: { email: string; otp: string }, { rejectWithValue }) => {
    try {
      const response = await authService.verifyOTP(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || { message: 'Failed to verify OTP' });
    }
  }
);

export const getTenantOptions = createAsyncThunk(
  'auth/getTenantOptions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await authService.getTenantOptions();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || { message: 'Failed to fetch tenant options' });
    }
  }
);