// src/components/EarlyAccessLandingPage.tsx

import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Grid, 
  styled,
  keyframes,
  Snackbar,
  Alert
} from '@mui/material';
import { 
  Timeline, 
  TimelineItem, 
  TimelineSeparator, 
  TimelineConnector, 
  TimelineContent, 
  TimelineDot 
} from '@mui/lab';
import { 
  Rocket, 
  TrendingUp, 
  GroupAdd, 
  AccessTime, 
  Assessment 
} from '@mui/icons-material';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const HeroSection = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
  backgroundSize: '400% 400%',
  animation: `${gradientAnimation} 15s ease infinite`,
  color: 'white',
  padding: theme.spacing(20, 0),
  textAlign: 'center',
}));

const FeatureIcon = styled(Box)(({ theme }) => ({
  fontSize: '3rem',
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const CTASection = styled(Box)(({ theme }) => ({
  background: '#f8f9fa',
  padding: theme.spacing(10, 0),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.dark,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.dark,
    },
  },
}));

const EarlyAccessLandingPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await fetch('/api/early-access', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        setSnackbar({
          open: true,
          message: 'Thank you for your interest! Please check your email for confirmation.',
          severity: 'success'
        });
        setEmail('');
      } else {
        throw new Error('Signup failed');
      }
    } catch (error) {
      console.error('Error:', error);
      setSnackbar({
        open: true,
        message: 'An error occurred. Please try again later.',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <HeroSection>
        <Container maxWidth="md">
          <Typography variant="h1" component="h1" gutterBottom fontWeight="bold">
            PesoHR
          </Typography>
          <Typography variant="h3" component="h2" gutterBottom>
            The Future of HR Management is Here
          </Typography>
          <Typography variant="h5" gutterBottom>
            Empower Your Workforce, Streamline Your Processes, Maximize Your Potential
          </Typography>
          <Button 
            variant="contained" 
            color="secondary" 
            size="large" 
            startIcon={<Rocket />}
            sx={{ mt: 4, py: 1.5, px: 4, fontSize: '1.2rem' }}
            href="#early-access"
          >
            Get Early Access
          </Button>
        </Container>
      </HeroSection>

      <Container maxWidth="lg">
        <Box my={10}>
          <Typography variant="h3" component="h2" gutterBottom textAlign="center" fontWeight="bold">
            Transform Your HR Operations
          </Typography>
          <Grid container spacing={6} mt={4}>
            {[
              { icon: <GroupAdd />, title: 'Intelligent Onboarding', description: 'Seamlessly integrate new hires with our AI-driven onboarding process' },
              { icon: <AccessTime />, title: 'Real-time Attendance Tracking', description: 'Monitor attendance with precision using our advanced biometric systems' },
              { icon: <Assessment />, title: 'Predictive Analytics', description: 'Leverage machine learning to forecast trends and make data-driven decisions' },
              { icon: <TrendingUp />, title: 'Performance Optimization', description: 'Boost productivity with our comprehensive performance management tools' },
            ].map((feature, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Box display="flex" alignItems="center">
                  <FeatureIcon>{feature.icon}</FeatureIcon>
                  <Box ml={3}>
                    <Typography variant="h5" component="h3" gutterBottom fontWeight="bold">
                      {feature.title}
                    </Typography>
                    <Typography variant="body1">
                      {feature.description}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>

      <Box bgcolor="primary.main" color="white" py={10}>
        <Container maxWidth="md">
          <Typography variant="h3" component="h2" gutterBottom textAlign="center" fontWeight="bold">
            Your Journey to HR Excellence
          </Typography>
          <Timeline position="alternate">
            {[
              'Sign Up for Early Access',
              'Receive Exclusive Onboarding',
              'Customize Your PesoHR Experience',
              'Transform Your Workforce',
              'Achieve Unprecedented Growth'
            ].map((step, index) => (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot color="secondary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant="h6" component="h3" fontWeight="bold">
                    {step}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Container>
      </Box>

      <CTASection id="early-access">
        <Container maxWidth="sm">
          <Typography variant="h3" component="h2" gutterBottom textAlign="center" fontWeight="bold">
            Be a Pioneer in HR Innovation
          </Typography>
          <Typography variant="h6" gutterBottom textAlign="center">
            Join the exclusive group of industry leaders shaping the future of HR management
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 4 }}>
            <StyledTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Enter Your Business Email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              sx={{ mt: 3, mb: 2, py: 1.5, fontSize: '1.1rem' }}
            >
              Secure Your Early Access Now
            </Button>
          </Box>
        </Container>
      </CTASection>

      <Box textAlign="center" py={4}>
        <Typography variant="subtitle1" fontWeight="bold">
          Launching Soon | Limited Spots Available | Don't Miss Out!
        </Typography>
      </Box>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EarlyAccessLandingPage;